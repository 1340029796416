"use client";
import { BarChart } from "@mui/x-charts";
import { abbreviateNumber } from "helpers/helpers";
import { Stack } from "@mui/material";

interface Props {
  data?: any;
}

export default function DashboardChart({ data }: Props) {
  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  const points = localStorage()?.valueType === "cash" ? true : false;

  // Prepare the data for the chart
  const chartData = data?.points?.map((point: any) => {
    const date = Object.keys(point)[0];
    const value = point[date];
    return { date, value };
  });

  const labels = chartData?.map((item: any) => item.date);
  const values = chartData?.map((item: any) => {
    return points ? item.value / 100 : item.value;
  });

  return data?.points?.length > 0 ? (
    <BarChart
      // margin={{ top: 20, bottom: 20 }}
      // skipAnimation
      margin={{ left: 80 }}
      grid={{ horizontal: true, vertical: true }}
      series={[
        {
          data: values,
          color: Boolean(points) ? "#46B358" : "#6F23FF",
        },
      ]}
      xAxis={[
        {
          data: labels,
          scaleType: "band",
        },
      ]}
      yAxis={[
        {
          scaleType: "linear",
          labelFormatter: (value: number) => {
            return points ? `$${value}` : abbreviateNumber(value);
          },
        } as any,
      ]}
    />
  ) : (
    <Stack
      sx={{
        height: 250,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      No data to display
    </Stack>
  );
}
