import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton, Stack } from "@mui/material";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro";
import { BlackTooltip, FontAwesomeIcon } from "helpers/helpers";

dayjs.extend(isBetween);

interface MetricDateRangeProps {
  date: any;
  setDate: any;
  dateRange?: any;
}
const MetricDateRange: React.FC<MetricDateRangeProps> = ({
  date,
  setDate,
  dateRange,
}) => {
  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs.utc();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs.utc();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs.utc();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs.utc();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Next Month",
      getValue: () => {
        const today = dayjs.utc();
        const startOfNextMonth = today.endOf("month").add(1, "day");
        return [startOfNextMonth, startOfNextMonth.endOf("month")];
      },
    },
    {
      label: "Prior Month",
      getValue: () => {
        const today = dayjs.utc();
        const startOfPriorMonth = today.startOf("month").subtract(1, "day");
        return [startOfPriorMonth.startOf("month"), startOfPriorMonth];
      },
    },
  ];
  // function isDayInRange(day: Dayjs) {
  //   return day.isBetween(
  //     dateRange?.start_date,
  //     dateRange?.end_date,
  //     "day",
  //     "[]"
  //   );
  // }
  // function ServerDay(props: PickersDayProps<Dayjs> & { dataDays?: number[] }) {
  //   const { dataDays = [], day, outsideCurrentMonth, ...other } = props;
  //   const isSelected = !outsideCurrentMonth && isDayInRange(day);

  //   return (
  //     <Badge
  //       key={day.toString()}
  //       overlap="circular"
  //       badgeContent={
  //         isSelected ? (
  //           <PlaylistAddCheckCircleSharpIcon
  //             sx={{ color: "green", background: "white", borderRadius: 50 }}
  //           />
  //         ) : undefined
  //       }
  //     >
  //       <PickersDay
  //         {...other}
  //         outsideCurrentMonth={outsideCurrentMonth}
  //         day={day}
  //       />
  //     </Badge>
  //   );
  // }

  function ActionList() {
    return (
      // Propagate the className such that CSS selectors can be applied
      <Stack
        width="100%"
        fontSize={12}
        p={2}
        pl={4}
        alignItems="center"
        justifyContent="center"
      >
        <BlackTooltip title="Find a date range where data lives">
          <IconButton
            sx={{ fontSize: 14 }}
            onClick={() => {
              setDate([
                dayjs.utc(dateRange?.start_date),
                dayjs.utc(dateRange?.end_date),
              ]);
            }}
          >
            <FontAwesomeIcon icon="bullseye" />
          </IconButton>
        </BlackTooltip>
        <Stack>Data Available:</Stack>
        <Stack sx={{ color: "#d38d03" }}>
          {dateRange?.start_date
            ? dayjs.utc(dateRange?.start_date).format("MM/DD/YY")
            : "N/A"}{" "}
          -
          {dateRange?.end_date
            ? dayjs.utc(dateRange?.end_date).format("MM/DD/YY")
            : "N/A"}
        </Stack>
      </Stack>
    );
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          // day: {
          //   dataDays: [], // No dynamic data, but could be added if needed
          // } as any,
          textField: {
            InputProps: {
              startAdornment: (
                <FontAwesomeIcon sx={{ mr: 1 }} icon="calendar" />
              ),
            },
            sx: {
              m: 0.5,
              "& .MuiInputBase-root": {
                width: "200px",
                backgroundColor: "#fff",
                cursor: "pointer",
                fontSize: "12px",
                border: "1px solid #adadad42",
                borderRadius: "10px",
                padding: "4px 10px 4px 10px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                cursor: "pointer",
              },
              "& .MuiSvgIcon-root": {
                marginRight: "10px",
              },
            },
          },
        }}
        slots={{
          field: SingleInputDateRangeField,
          actionBar: dateRange ? ActionList : undefined,
          // day: ServerDay,
        }}
        value={date}
        onChange={(newValue) => {
          setDate(newValue);
        }}
      />
    </LocalizationProvider>
  );
};

export default MetricDateRange;
