import { Stack } from "@mui/material";
import {
  DataGridPremium,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import SearchBox from "components/CustomUI/SearchBox";
import dayjs from "dayjs";
import { CustomPagination } from "helpers/helpers";
import useApi from "lib/useApi";
import { useEffect, useState } from "react";

interface Table {
  date?: any;
  admin?: any;
  orgId?: any;
  crowd?: any;
  type?: any;
  downloading?: any;
  setDownloading?: any;
  setDownloadReady?: any;
  selectedUser?: any;
}

export default function OrgEarningsTable({
  date,
  admin,
  orgId,
  crowd,
  type,
  downloading,
  setDownloading,
  setDownloadReady,
  selectedUser,
}: Table) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [searchCriteria, setSearchCriteria] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const apiRef = useGridApiRef();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "date", sort: "desc" },
  ]);
  const sortField = sortModel[0]?.field || "";
  const sortOrder = sortModel[0]?.sort || "";

  const { data: earnings, isLoading } = useApi(
    `/api/orgs/${orgId}/earnings?start_date=${date[0]}&end_date=${
      date[1]
    }&filter=${searchCriteria}${
      type === "all" ? "" : `&earning_type=${type}`
    }&page=${paginationModel.page + 1}&per_page=${paginationModel.pageSize}${
      selectedUser === null || selectedUser === ""
        ? ""
        : `&member_id=${selectedUser.id}`
    }${
      crowd === "All" || crowd === undefined ? "" : `&crowd_id=${crowd}`
    }&sort[field]=${sortField}&sort[order]=${sortOrder}`,
    admin
  );

  const earningsData = earnings?.transactions || [];

  useEffect(() => {
    if (earnings) {
      setRowCount(earnings?.pagination?.total_items);
    }
  }, [earnings]);

  const { data: download, isLoading: transactionsLoading } = useApi(
    `/api/orgs/${orgId}/earnings?start_date=${date[0]}&end_date=${date[1]}${
      type === "all" ? "" : `&earning_type=${type}`
    }&page=${1}&per_page=${500}${
      selectedUser === null || selectedUser === ""
        ? ""
        : `&member_id=${selectedUser.id}`
    }${crowd === "All" || crowd === undefined ? "" : `&crowd_id=${crowd}`}`,
    admin
  );

  const transactions = !transactionsLoading ? download?.transactions : [];

  useEffect(() => {
    if (downloading) {
      exportToCSV(downloadRows);
    }
  }, [downloading]);

  useEffect(() => {
    if (!transactionsLoading) {
      setDownloadReady(true);
    }
  }, [transactions]);

  const downloadRows = transactions?.map((row: any) => {
    return {
      date: dayjs.utc(row.occurred_at).format("MM/DD/YY"),
      type: row.transaction_type,
      crowd: row.crowd.name,
      user: row.member.full_name,
      amount: row.amount,
      description: row.description.replace(/,/g, ""),
    };
  });

  function exportToCSV(data) {
    setDownloading(false);
    let csvContent = "data:text/csv;charset=utf-8,";

    // Check if data is non-empty
    if (data.length === 0) return;

    // Header
    const headers = Object.keys(data[0]);
    csvContent += headers.join(",") + "\n";

    // Data
    data.forEach((item) => {
      const row = headers.map((header) => item[header]).join(",");
      csvContent += row + "\n";
    });

    // Trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `Org Earning Transactions - ${new Date()}.csv`
    );
    document.body.appendChild(link);

    link.click();
  }

  const rows = earningsData?.map((row: any) => {
    return {
      ...row,
      id: row.id,
      first_name: row.member.first_name,
      last_name: row.member.last_name,
      email: row.member.email,
      status: row.member.status,
      role: row.member.role,
      amount: row.amount,
      transaction_type: row.transaction_type,
      crowd_name: row.crowd.name,
      description: row.description,
      date: dayjs.utc(row.occurred_at).format("MM/DD/YY"),
    };
  });

  const columns = [
    { field: "date", headerName: "Date", width: 80 },
    { field: "first_name", headerName: "First Name", width: 130 },
    { field: "last_name", headerName: "Last Name", width: 130 },
    { field: "crowd_name", headerName: "Crowd", width: 130 },
    { field: "transaction_type", headerName: "Type", width: 70 },
    { field: "description", headerName: "Description", width: 180 },
    { field: "amount", headerName: "Amount", width: 80 },
  ];

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  return (
    <Stack sx={{ height: "340px", mt: 2, display: "grid" }}>
      <SearchBox
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
      />
      <DataGridPremium
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 2,
          bottom: 2,
        })}
        apiRef={apiRef}
        keepNonExistentRowsSelected
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableRowGrouping
        disableRowSelectionOnClick
        checkboxSelection={false}
        sx={{ height: 300 }}
        pagination
        density="compact"
        pageSizeOptions={[5, 10, 25, 50, 100]} // Add this line to set the available page size options
        paginationMode="server"
        rowCount={rowCount}
        getRowId={(row) => row.id}
        rows={rows}
        columns={columns}
        loading={isLoading}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        paginationModel={paginationModel}
        sortingOrder={["desc", "asc"]}
        sortingMode="server"
        onPaginationModelChange={setPaginationModel}
        slots={{
          pagination: CustomPagination,
        }}
      />
    </Stack>
  );
}
