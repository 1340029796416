
import DashboardLayout from "components/mainPage/Dashboard";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import DashboardPage from "components/dashboard/dashboardPage";

export default withPageAuthRequired(function Dashboard({ user }) {
  // if (error) return <div>oops... {error.message}</div>;

  return (
    <DashboardLayout pageIndex={0}>
      <DashboardPage user={user} />
    </DashboardLayout>
  );
});
