import { useEffect, useMemo } from "react";
import { BarChart } from "@mui/x-charts";
import { abbreviateNumber } from "helpers/helpers";
import { Stack } from "@mui/material";
import useApi from "lib/useApi";
import { useLocalStorage } from "contexts/LocalStorageContext";

interface ChartData {
  date?: any;
  selectedUser?: any;
  orgId?: any;
  crowd?: any;
  type?: any;
  setDownloadableChartData?: any;
}

export default function OrgEarningsBarChart({
  date,
  orgId,
  crowd,
  type,
  setDownloadableChartData,
  selectedUser,
}: ChartData) {
  const { admin, allMembersId } = useLocalStorage();
  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  const { data: chart } = useApi(
    `/api/orgs/${orgId}/earnings/chart_data?start_date=${date[0]}&end_date=${
      date[1]
    }${crowd === "All" || crowd === undefined ? "" : `&crowd_id=${crowd}`}${
      type === "all" ? "" : `&earning_type=${type}`
    }${
      selectedUser !== undefined && selectedUser !== "" && selectedUser !== null
        ? `&member_id=${selectedUser?.id} `
        : ""
    }`,
    admin
  );
  const points = localStorage()?.valueType === "cash" ? true : false;

  // Memoize the chart data
  const chartData = useMemo(() => {
    return chart?.points?.map((point: any) => {
      const date = Object.keys(point)[0];
      const value = point[date];
      return { date, value };
    });
  }, [chart]);

  const labels = useMemo(
    () => chartData?.map((item: any) => item.date),
    [chartData]
  );
  const values = useMemo(
    () =>
      chartData?.map((item: any) => {
        return points ? item.value / 100 : item.value;
      }),
    [chartData, points]
  );

  useEffect(() => {
    setDownloadableChartData(chartData);
  }, [chartData, setDownloadableChartData]);

  return chart?.points?.length > 0 ? (
    <BarChart
      margin={{ left: 80 }}
      grid={{ horizontal: true, vertical: true }}
      series={[
        {
          data: values,
          color: Boolean(points) ? "#46B358" : "#6F23FF",
        },
      ]}
      xAxis={[
        {
          data: labels,
          scaleType: "band",
        },
      ]}
      yAxis={[
        {
          scaleType: "linear",
          labelFormatter: (value: number) => {
            return points ? `$${value}` : abbreviateNumber(value);
          },
        } as any,
      ]}
    />
  ) : (
    <Stack
      sx={{
        height: 250,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      No data to display
    </Stack>
  );
}
