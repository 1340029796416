import styles from "./index.module.css";
import {
  Grid,
  Box,
  styled,
  Switch,
  Stack,
  Paper,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardStatBox from "components/tailwind_mui/stats";

import Users from "/assets/icons/tailwind/users.svg";
import Megaphone from "/assets/icons/tailwind/megaphone.svg";
import Wallet from "/assets/icons/tailwind/wallet.svg";
import PointsIcon from "/assets/icons/tailwind/star.svg";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import {
  abbreviateNumber,
  BlackTooltip,
  CustomPagination,
  FontAwesomeIcon,
} from "helpers/helpers";

import useApi from "lib/useApi";
import OrgRedemption from "./components/OrgRedemptionHistoryPie";
import OrgRedemptionHistoryTable from "./components/OrgRedemptionHistoryTable";
import {
  DataGridPremium,
  GridCellParams,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import ParticipantEarningsGraph from "./components/ParticipantEarningsGraph";
import SearchBox from "components/CustomUI/SearchBox";
import { useLocalStorage } from "contexts/LocalStorageContext";
interface DashboardProps {
  user: any;
}

export default function Dashboard({ user }: DashboardProps) {
  const apiRef = useGridApiRef();
  const router = useRouter();
  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };
  const { staff, admin, orgId, isMobile } = useLocalStorage();
  const [adminTab, setAdminTab] = useState(0);

  const { data: memberData } = useApi(
    `/api/orgs/${orgId}/members/my_member`,
    true
  );

  const pointsLocal = localStorage()?.valueType === "cash" ? true : false;
  const [points, setPoints] = useState(pointsLocal);
  const [searchCriteria, setSearchCriteria] = useState("");
  const crowds = memberData?.crowd_count || 0;
  const campaigns = memberData?.campaign_count || 0;
  const walletCash = memberData?.wallet_amount || 0;
  const walletPoints = walletCash / 100 || 0;

  // start of query params
  useEffect(() => {
    if (!router.isReady) return;

    const { adminTab } = router.query;

    // If the user is not an admin, ensure adminTab is set to 0
    if (!admin) {
      setAdminTab(0);
    } else if (
      typeof adminTab === "string" &&
      adminTab.length > 0 &&
      !isNaN(Number(adminTab))
    ) {
      setAdminTab(Number(adminTab));
    }
  }, [router, router.isReady, admin]);

  useEffect(() => {
    const updatedQuery = {
      adminTab,
    };

    // Update URL only if open is true
    if (router.pathname === "/dashboard") {
      router.replace(
        {
          pathname: `/dashboard`,
          query: updatedQuery,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [adminTab, router.pathname]);
  // end of query params

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "date", sort: "desc" },
  ]);
  const sortField = sortModel[0]?.field || "";
  const sortOrder = sortModel[0]?.sort || "";

  const { data: walletData, isLoading } = useApi(
    `/api/orgs/${orgId}/members/my_wallet_history?page=${
      paginationModel.page + 1
    }&per_page=${
      paginationModel.pageSize
    }&filter=${searchCriteria}&sort[field]=${sortField}&sort[order]=${sortOrder}`,
    true
  );

  const { data: earningsReport, mutate: mutateEarningsReport } = useApi(
    `/api/orgs/${orgId}/members/my_wallet_history.csv?page=${
      paginationModel.page + 1
    }&per_page=${paginationModel.pageSize}&filter=${searchCriteria}`,
    true
  );

  const rowCountRoot = walletData?.pagination?.total_items || 0;
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    if (rowCountRoot) {
      setRowCount(rowCountRoot);
    }
  }, [rowCountRoot]);

  useEffect(() => {
    if (walletData) {
      setSavedWalletData(walletData);
    }
  }, [walletData]);

  const [savedWalletData, setSavedWalletData] = useState([]);
  const walletHistory = (savedWalletData as any)?.transactions || [];

  const rows = walletHistory.map((row: any) => {
    return {
      ...row,
      id: row.id,
      date: row.occurred_at,
      link: row?.gift_card_links?.[0] || "",
      amount: row.amount,
      description: row.description,
      type: row.transaction_type,
    };
  });

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 100,
      renderCell: (params: GridCellParams) => {
        return <Box>{dayjs.utc(params?.row?.date).format("MM/DD/YYYY")}</Box>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 400,
      renderCell: (params: GridCellParams) => {
        return (
          <span
            style={{
              textDecoration: params?.row?.link ? "underline" : "none",
            }}
          >
            {params?.row?.link ? (
              <a
                href={params?.row?.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                [ {params?.row?.type} ] - {params?.row?.description}{" "}
              </a>
            ) : (
              `[ ${params?.row?.type} ] - ${params?.row?.description}`
            )}
          </span>
        );
      },
    },
    {
      field: "amount",
      headerName: "Value",
      width: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <Box>
            {!points && (
              <Box
                sx={{
                  color:
                    parseInt(params?.row?.value) <= 0 ? "#FF244E" : "#6F23FF",
                }}
              >
                {params?.row?.amount}
              </Box>
            )}
            {points && (
              <Box
                sx={{
                  color:
                    parseInt(params?.row?.amount) <= 0 ? "#FF244E" : "#46B358",
                }}
              >
                {`$${(parseInt(params?.row?.amount) / 100).toFixed(2)}`}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const handleChangeAdmin = (event: React.SyntheticEvent, newValue: number) => {
    setAdminTab(newValue);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const downloadEarningsReport = async () => {
    let data = await mutateEarningsReport();
    if (data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `${dayjs.utc()}-My_Wallet_History.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={11} md={6} xl={6}>
          <Box className={styles.topBox}>
            <Box className={styles.title}>My Dashboard</Box>
          </Box>
        </Grid>
        {admin && (
          <Grid item xs={12} sm={11} md={6} xl={6}>
            <Stack
              direction="row"
              sx={{
                justifyContent: isMobile ? "center" : "flex-end",
                width: "100%",
                mb: isMobile ? 2 : 0,
                transform: isMobile ? "translateY(-20px)" : "",
              }}
            >
              <StyledTabs value={adminTab} onChange={handleChangeAdmin}>
                <StyledTab label="Participant" />
                <StyledTab label="Admin" />
              </StyledTabs>
            </Stack>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <DashboardStatBox
            icon={Users}
            title="Crowds"
            value={crowds}
            from="#413096"
            to="#231763"
            url={"/socialcrowds"}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardStatBox
            icon={Megaphone}
            title="Campaigns"
            value={campaigns}
            from="#303c96"
            to="#172263"
            url={"/campaigns"}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DashboardStatBox
            icon={points ? Wallet : PointsIcon}
            title={points ? "Wallet" : "Points"}
            value={`${points ? "$" : ""}${
              points ? walletPoints : abbreviateNumber(walletCash)
            }`}
            from={points ? "#46B358" : "#743096"}
            to={points ? "#175d23" : "#431763"}
            url={"/pointsandrewards"}
          />
        </Grid>
      </Grid>
      <Stack
        mt={2}
        className={styles.mobile}
        direction="row"
        width="100%"
        justifyContent="center"
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Stack>Points</Stack>
          <SwitcherPoints
            checked={points}
            onChange={(e) => {
              let string = e.target.checked.toString();
              window.localStorage.setItem(
                "valueType",
                string === "true" ? "cash" : "points"
              );
              setPoints(e.target.checked);
            }}
          />
          <Stack>Cash</Stack>
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        {adminTab === 1 && !isMobile ? (
          <Grid item xs={12} lg={6}>
            <OrgRedemptionHistoryTable />
          </Grid>
        ) : null}
        {adminTab === 1 ? (
          <Grid item xs={12} lg={6}>
            <OrgRedemption />
          </Grid>
        ) : null}
        {adminTab === 0 && !isMobile ? (
          <Grid item xs={12} lg={adminTab === 0 ? 6 : 12}>
            <ParticipantEarningsGraph />
          </Grid>
        ) : null}

        {adminTab === 0 ? (
          <Grid item xs={12} lg={6} width="100%">
            <Paper
              className={styles.midTitles}
              sx={{ height: isMobile ? "100%" : "450px" }}
              style={{ width: "100%" }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                mb={2}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    fontSize: "1.2rem",
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  <Stack>Wallet History</Stack>
                  <BlackTooltip title="Download CSV">
                    <IconButton onClick={() => downloadEarningsReport()}>
                      <FontAwesomeIcon icon="file-arrow-down" />
                    </IconButton>
                  </BlackTooltip>
                </Stack>
              </Stack>
              {walletHistory ? (
                <div
                  style={{
                    width: "100%",
                    height: "85%",
                    display: rowCount === 0 || isLoading ? "block" : "grid",
                  }}
                >
                  <SearchBox
                    searchCriteria={searchCriteria}
                    setSearchCriteria={setSearchCriteria}
                  />

                  <DataGridPremium
                    {...walletHistory}
                    getRowSpacing={(params) => ({
                      top: params.isFirstVisible ? 0 : 2,
                      bottom: 2,
                    })}
                    apiRef={apiRef}
                    sortModel={sortModel}
                    sortingOrder={["desc", "asc"]}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    keepNonExistentRowsSelected
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowGrouping
                    pagination
                    sx={{ height: 300 }}
                    density="compact"
                    pageSizeOptions={[5, 10, 25, 100]}
                    paginationMode="server"
                    rowCount={rowCount || 0}
                    getRowId={(row) => row.id}
                    rows={rows}
                    columns={columns}
                    loading={isLoading}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    slots={{
                      pagination: CustomPagination,
                    }}
                  />
                </div>
              ) : null}
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}

const SwitcherPoints = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",

      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundColor: "#46B358",
        borderRadius: "50%",
        backgroundPositionX: "right",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 18 18"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M3.924 15.98V14.18C3.372 14.12 2.814 13.97 2.25 13.73C1.698 13.49 1.218 13.184 0.81 12.812L1.746 11.408C2.166 11.744 2.592 12.014 3.024 12.218C3.468 12.422 3.93 12.524 4.41 12.524C4.998 12.524 5.436 12.386 5.724 12.11C6.012 11.834 6.156 11.456 6.156 10.976C6.156 10.58 6.036 10.25 5.796 9.986C5.568 9.71 5.268 9.47 4.896 9.266C4.524 9.062 4.128 8.858 3.708 8.654C3.288 8.438 2.892 8.198 2.52 7.934C2.148 7.67 1.842 7.352 1.602 6.98C1.374 6.596 1.26 6.122 1.26 5.558C1.26 4.718 1.5 4.022 1.98 3.47C2.472 2.906 3.12 2.552 3.924 2.408V0.571999H5.364V2.372C5.94 2.444 6.432 2.612 6.84 2.876C7.26 3.128 7.638 3.428 7.974 3.776L6.912 4.982C6.576 4.67 6.252 4.436 5.94 4.28C5.628 4.112 5.256 4.028 4.824 4.028C4.344 4.028 3.96 4.154 3.672 4.406C3.396 4.646 3.258 5 3.258 5.468C3.258 5.828 3.372 6.128 3.6 6.368C3.84 6.608 4.146 6.824 4.518 7.016C4.89 7.208 5.286 7.406 5.706 7.61C6.126 7.802 6.522 8.036 6.894 8.312C7.266 8.576 7.566 8.912 7.794 9.32C8.034 9.716 8.154 10.22 8.154 10.832C8.154 11.684 7.908 12.404 7.416 12.992C6.936 13.58 6.252 13.958 5.364 14.126V15.98H3.924Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#6F23FF",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

interface StyledTabProps {
  label: string;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    // backgroundColor: "#6F23FF",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(18),
  marginRight: theme.spacing(1),
  // border: "1px solid #6F23FF",
  // borderRadius: "10px",
  "&.Mui-selected": {
    color: "white",
    borderRadius: "10px",
    backgroundColor: "#6F23FF",
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#5c037c",
  },
}));
