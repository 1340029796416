"use server";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "helpers/helpers";

interface SearchBoxProps {
  searchCriteria: string;
  setSearchCriteria: (criteria: any) => void;
}
const SearchBox: React.FC<SearchBoxProps> = ({
  searchCriteria,
  setSearchCriteria,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      placeholder="Search..."
      value={searchCriteria}
      sx={{ mb: 1 }}
      InputProps={{
        startAdornment: (
          <FontAwesomeIcon icon="search" sx={{ fontSize: "0.9rem", mr: 2 }} />
        ),
      }}
      onChange={(e) => setSearchCriteria(e.target.value)}
    />
  );
};
export default SearchBox;
