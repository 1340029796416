"use client";

import React, { useState } from "react";

interface CardImageProps {
  item: {
    image_url: string | null;
    name: string;
  };
  colors: string[];
  redemptionSummary: any[]; // Adjust the type as needed
  Placeholder: { src: string };
}

const CardImage: React.FC<CardImageProps> = ({
  item,
  colors,
  redemptionSummary,
  Placeholder,
}) => {
  const [imgSrc, setImgSrc] = useState(item.image_url);

  return (
    <img
      width="60px"
      height="100%"
      onError={() => {
        setImgSrc("error");
      }}
      style={{
        borderRadius: "8px",
        border: "1px solid #fff",
        boxShadow: `-2px -2px 6px 1px ${
          colors[redemptionSummary?.indexOf(item)]
        }`,
      }}
      src={imgSrc === "error" || imgSrc === null ? Placeholder.src : imgSrc}
      alt={item.name.slice(0, 4) + "..."}
    />
  );
};

export default CardImage;
