import { useEffect, useState } from "react";
import { Box, Paper, Stack, MenuItem, IconButton, Divider, Autocomplete, TextField } from "@mui/material";
import dayjs from "dayjs";
import { BlackTooltip, FontAwesomeIcon } from "helpers/helpers";
import DoughnutChart from "components/chart/AdminPieChart";
import styles from "./index.module.css";
import useApi from "lib/useApi";
import SelectorWithIcon from "components/CustomUI/SelectorWithIcon";
import RangePicker from "components/CustomUI/RangePicker";
import CustomAutoComplete from "components/CustomUI/CustomAutoComplete";
import { useLocalStorage } from "contexts/LocalStorageContext";

export default function OrgRedemption() {
  const { admin, allMembersId, isMobile } = useLocalStorage();
  const [selectedCrowd, setSelectedCrowd] = useState<{
    id: string;
    name: string;
  } | null>({
    id: "All",
    name: "All Crowds",
  });
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchCriteriaUsers, setSearchCriteriaUsers] = useState("");
  const [type, setType] = useState("all");
  const [crowdsListHook, setCrowdsListHook] = useState([
    { id: "All Members", name: "All Members" },
  ]);
  const [date, setDate] = useState([
    dayjs.utc().startOf("month"),
    dayjs.utc().endOf("month"),
  ]);
  const [valueType, setValueType] = useState("points");
  const [options, setOptions] = useState<Array<{ id: string; name: string }>>(
    []
  );
  const [inputValue, setInputValue] = useState("");

  const [crowdsPage, setCrowdsPage] = useState(12);
  const [usersPage, setUsersPage] = useState(12);
  const [totalCrowds, setTotalCrowds] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const valueType = localStorage()?.valueType;
      setValueType(valueType);
    }
  }, [localStorage()?.admin]);
  // end

  const findIdOfCrowd = (crowdName: string) => {
    const crowd = crowdsListHook?.find(
      (crowd: any) => crowd.name === crowdName
    );
    return crowd?.id;
  };

  const findNameOfCrowd = (crowdName: string) => {
    const crowd = crowdsListHook?.find(
      (crowd: any) => crowd.name === crowdName
    );
    return crowd?.name || "All Crowds";
  };

  const orgId = localStorage()?.orgSelected;

  const { data: allCrowds, mutate: mutateAllCrowds } = useApi(
    `/api/orgs/${orgId}/crowds?filter=${searchCriteria}&page=1&per_page=${crowdsPage}`,
    admin
  );

  const allCrowdsList = allCrowds?.crowds;

  const {
    data: crowdUsers,
    mutate: mutateUsers,
    isLoading: usersLoading,
  } = useApi(
    `/api/orgs/${orgId}/crowds/${
      selectedCrowd?.id === "All" || selectedCrowd === null
        ? allMembersId
        : selectedCrowd?.id
    }/everyone?filter=${searchCriteriaUsers}&page=1&per_page=${usersPage}`,
    admin
  );
  // Handle scroll event for crowds
  const handleCrowdsScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
        listboxNode.scrollHeight - 10 &&
      crowdsPage < totalCrowds
    ) {
      setCrowdsPage((prevPage) => prevPage + 10);
    }
  };

  // Handle scroll event for users
  const handleUsersScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
        listboxNode.scrollHeight - 10 &&
      usersPage < totalUsers
    ) {
      setUsersPage((prevPage) => prevPage + 10);
    }
  };

  useEffect(() => {
    setTotalUsers(crowdUsers?.pagination?.total_items);
  }, [crowdUsers]);

  useEffect(() => {
    if (allCrowds === undefined) return;
    const fetchedOptions = allCrowds?.crowds.map((crowd: any) => ({
      id: crowd?.id,
      name: `${crowd.name || ""}`,
    }));
    const all_crowds = [{ id: "All", name: "All Crowds" }];
    setTotalCrowds(allCrowds?.pagination?.total_items);
    setOptions([...all_crowds, ...fetchedOptions]);
  }, [allCrowds]);

  const { data: redemption_details } = useApi(
    `/api/orgs/${orgId}/rewards/redemption_details?crowd_id=${
      selectedCrowd?.id === "All" || selectedCrowd === null
        ? allMembersId
        : selectedCrowd?.id
    }&start_date=${date[0]}&end_date=${date[1]}${
      type !== "all" ? `&reward_type=${type}` : ""
    }${
      selectedUser === null || selectedUser === ""
        ? ""
        : `&member_id=${selectedUser.id}`
    }`,
    admin
  );

  const { data: redemptionSummary } = useApi(
    `/api/orgs/${orgId}/rewards/redemption_summary?crowd_id=${
      selectedCrowd?.id === "All" || selectedCrowd === null
        ? allMembersId
        : selectedCrowd?.id
    }&start_date=${date[0]}&end_date=${date[1]}${
      type !== "all" ? `&reward_type=${type}` : ""
    }${
      selectedUser === null || selectedUser === ""
        ? ""
        : `&member_id=${selectedUser.id}`
    }`,
    admin
  );

  useEffect(() => {
    if (admin && allCrowdsList?.length > 0) {
      const crowdsList = allCrowdsList?.map((crowd: any) => ({
        id: crowd?.id,
        name: crowd?.name,
      }));
      setCrowdsListHook(crowdsList);
    }
  }, [allCrowdsList]);

  const [redemptionHistory, setRedemptionHistory] = useState([]);

  useEffect(() => {
    const flattenedRedemptions = redemption_details?.redemptions?.map((r) => ({
      FirstName: r.member.first_name,
      LastName: r.member.last_name,
      Email: r.member.email,
      Reward: r.reward.name,
      RewardType: r.reward.reward_type,
      redemption_created_at: r.redemption.created_at,
      redemption_dollars: r.redemption.dollars,
      redemption_points: r.redemption.points,
    }));

    // Sort the flattened redemptions by redemption_created_at in ascending order
    flattenedRedemptions?.sort((a, b) => {
      return (
        new Date(a.redemption_created_at).getTime() -
        new Date(b.redemption_created_at).getTime()
      );
    });

    setRedemptionHistory(flattenedRedemptions);
  }, [redemption_details]);

  function exportToCSV(data) {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Check if data is non-empty
    if (data.length === 0) return;

    // Header
    const headers = Object.keys(data[0]);
    csvContent += headers.join(",") + "\n";

    // Data
    data.forEach((item) => {
      const row = headers.map((header) => item[header]).join(",");
      csvContent += row + "\n";
    });

    // Trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "flattened_data.csv");
    link.setAttribute(
      "download",
      `${findNameOfCrowd(
        selectedCrowd?.name ?? ""
      )} - SocialCrowd Redemptions - ${new Date()}.csv`
    );
    document.body.appendChild(link);
    link.click();
  }

  const clearSelectedUser = () => {
    setSearchCriteria("");
    setSelectedUser("");
  };

  return (
    <Paper
      className={styles.midTitles}
      sx={{ minHeight: isMobile ? "100%" : "500px" }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            fontSize: "1.2rem",
            whiteSpace: "nowrap",
            fontWeight: "bold",
          }}
        >
          <Stack>Org Redemption History</Stack>
          <BlackTooltip title="Download CSV">
            <IconButton onClick={() => exportToCSV(redemptionHistory)}>
              <FontAwesomeIcon icon="file-arrow-down" />
            </IconButton>
          </BlackTooltip>
        </Stack>
        <RangePicker date={date} setDate={setDate} />
      </Stack>
      <Stack
        width="100%"
        justifyContent="flex-end"
        mt={2}
        direction="row"
        spacing={1}
        flexWrap="wrap"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {/* <FontAwesomeIcon icon="user-group-simple" /> */}
          <Autocomplete
            sx={{ minWidth: 200 }}
            options={options || []}
            getOptionLabel={(option) => option.name}
            value={selectedCrowd}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              clearSelectedUser();
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              setSelectedCrowd(newValue);
              if (newValue) {
                setInputValue(newValue.name);
              }
            }}
            filterSelectedOptions
            ListboxProps={{
              onScroll: handleCrowdsScroll,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                onChange={(e) => {
                  setSearchCriteria(e.target.value);
                  setCrowdsPage(1); // Reset page to 1 for new search
                  setOptions([]);
                }}
                placeholder="Select Crowd..."
              />
            )}
          />
        </Stack>

        <CustomAutoComplete
          type="user"
          // icon="user"
          placeholder="Select User..."
          setSearchCriteria={setSearchCriteriaUsers}
          mutate={mutateUsers}
          searchCriteria={searchCriteriaUsers}
          data={crowdUsers}
          setValue={setSelectedUser}
          value={selectedUser}
          isLoading={usersLoading}
          ListboxProps={{
            onScroll: handleUsersScroll,
          }}
        />

        <SelectorWithIcon
          value={type}
          onChange={(e) => setType(e.target.value)}
          // icon="award"
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"gift_card"}>Gift Cards</MenuItem>
          <MenuItem value={"custom"}>Custom Rewards</MenuItem>
        </SelectorWithIcon>
      </Stack>

      <Divider sx={{ mt: 1 }} />

      <Box
        sx={{
          position: "relative",
          height: "320px",
          pt: 2,
        }}
      >
        {redemptionSummary ? (
          <DoughnutChart
            redemptionSummary={redemptionSummary}
            valueType={valueType}
          />
        ) : null}
      </Box>
    </Paper>
  );
}
