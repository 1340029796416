import { Box, SvgIcon, Paper } from "@mui/material";
import React, { useState } from "react";
import styles from "./index.module.css";
import Link from "next/link";

interface DashboardStatBoxProps {
  icon?: any;
  title?: string;
  value?: any;
  color?: string;
  muiIcon?: any;
  from?: string;
  to?: string;
  url?: any;
}

export default function DashboardStatBox({
  icon,
  title,
  value,
  color,
  muiIcon,
  from,
  to,
  url,
}: DashboardStatBoxProps = {}) {
  function Icon() {
    return (
      <SvgIcon
        component={icon}
        sx={{ fontSize: 25, color: "white", fill: "white" }}
        inheritViewBox
      />
    );
  }

  // <Link href="/campaigns">

  return (
    <Link href={url ? url : ""}>
      <Paper
        className={url ? styles.boxesHover : styles.boxes}
        style={{ cursor: url ? "pointer" : "auto" }}
      >
        <Box
          style={{
            height: 70,
            width: 70,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            background: `linear-gradient(to right, ${from}, ${to})`,
          }}
        >
          {muiIcon ? muiIcon : <Icon />}
        </Box>
        <Box pl={2}>
          <Box
            style={{
              // color: "#9390a3",
              fontSize: ".9rem",
              fontWeight: "bold",
            }}
          >
            {title}
          </Box>
          <Box style={{ fontSize: "2em", fontWeight: "bold" }}>{value}</Box>
        </Box>
      </Paper>
    </Link>
  );
}
